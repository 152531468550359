import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import axios from 'axios'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        isLoggedIn: !!localStorage.getItem("token"),
        userType: localStorage.getItem('userType'),
        userData: {},
        email: localStorage.getItem('email'),
        uType: null,
        role: null,
    name: null,

        menus: [],
        initial: "",
        appLoading: false
    },
    mutations: {
        setRole(state, role) {
            state.role = role;
          },
          setName(state, name) {
            state.name = name;
          },
        setuType(state, uType) {
            state.uType = uType;
        },
        userData(state, item) {
            state.userData = item
        },
        email(state, item) {
            state.email = item
            localStorage.setItem("email",item)
        },

        sessionOut(state) {
            localStorage.removeItem("token");
            state.isLoggedIn = false;
            router.push("/");
        },
        logoutUser(state) {
            axios({
                method: "GET",
                url: "/logout/",
                headers: {
                    "token": localStorage.getItem("token")
                }
            }).then(response => {
                if (response.data) {
                    state.isLoggedIn = false
                    state.userData = {}
                    state.initial = "";
                    localStorage.removeItem("token");

                    router.push('/')
                }
            })
        },
        role(state, item) {
            state.role = item
        },
        loginUser(state, payload) {
            localStorage.setItem("token", payload);
            state.isLoggedIn = true
        },

    },

})